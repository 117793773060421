<template>
  <div>
    <el-descriptions title="基本信息" :style="isHandle ? style1 : null">
      <el-descriptions-item :label="info.releaseTenantIsPersonal ? '询价人' : '询价单位'">
        {{ info.releaseTenantIsPersonal ? `个人用户【${handleEncrypt(info.inquirePriceParty)}】` : handleEncrypt(info.inquirePriceParty, -2) }}
      </el-descriptions-item>
      <el-descriptions-item label="业务类型">{{ defaultFormat(info.businessTypeDesc) }}</el-descriptions-item>
      <el-descriptions-item label="交付方式">{{ defaultFormat(info.deliveryMethodDesc) }}</el-descriptions-item>
      <el-descriptions-item label="期望交期">{{ formatDate(info.expectedDeliveryDate) }}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{ handleEncrypt(info.createUserName) }}</el-descriptions-item>
      <el-descriptions-item label="创建人电话">{{ handleEncrypt(info.createUserPhone) }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ formatDate(info.releaseTime, 'YYYY-MM-DD HH:mm:ss')
        }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style2 : null">
      <el-descriptions-item label="交付地址">{{ $route.name === 'RECOMMEND_BUSINESS_OPPORTUNITIES' ?
        defaultFormat(`${info.deliveryProvinceName || ''}${info.deliveryCityName ||
          ''}`) : defaultFormat(`${info.deliveryProvinceName || ''}${info.deliveryCityName ||
            ''}${info.deliveryAreaName || ''}${info.deliveryDetailAddress || ''}`) }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style2 : null">
      <el-descriptions-item label="询单备注">{{ defaultFormat(info.inquirePriceRemark) }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style3 : null">
      <el-descriptions-item label="附件">
        <div v-for="img in (info.inquirePriceAnnexList || [])" :key="img.url" class="fileContainer">
          <el-link type="primary" :href="img.url" :underline="false" target="blank"><i class="el-icon-document"
              style="color:#FF5959;margin-right:4px;"></i> {{ img.annexName }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="device-table" :colon="false" :style="isHandle ? style4 : null" :column="1" :labelStyle="{ margin: 0 }">
      <span slot="title">
        需求清单
        <el-button type="primary" size="mini" @click="handleExport" :loading="loading">{{ loading ? '导出中' : '导出'
          }}</el-button>
      </span>
      <el-descriptions-item>
        <xz-table ref="table" :columns="columns" :data="info.sparePartList" :scroll="{ x: '100%', y: 600 }">
          <span slot="quantity" slot-scope="text, record">
            {{ text }}{{ record.unitName }}
          </span>
          <span slot="imageList" slot-scope="text">
            <span v-if="text && text.length > 0">
              <el-image style="width: 32px; height: 32px" :src="text[0].url"
                :preview-src-list="text.map(i => i.url)" /><span v-if="text.length > 1"
                style="color:#3182bd; margin-left: 2px;">...</span>
            </span>
            <span v-else>--</span>
          </span>
        </xz-table>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :style="isHandle ? style4 : null">
      <span slot="title">
        收货信息
        <span style="color: #FAB007; font-size: 12px;">
          <i class="el-icon-warning-outline"></i>待报价入围后可查看交付信息
        </span>
      </span>
      <el-descriptions-item label="收货人">{{ defaultFormatReceiverInfo(info.deliveryUserName) }}</el-descriptions-item>
      <el-descriptions-item label="收货电话">{{ defaultFormatReceiverInfo(info.deliveryPhone) }}</el-descriptions-item>
      <el-descriptions-item label="邮编">{{ defaultFormatReceiverInfo(info.deliveryPostcode) }}</el-descriptions-item>
      <el-descriptions-item label="收货地址">{{ defaultFormatReceiverInfo(`${info.deliveryProvinceName ||
        ''}${info.deliveryCityName ||
        ''}${info.deliveryAreaName || ''}${info.deliveryDetailAddress || ''}`) }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';
import { inquirePriceExportSparePart } from '@/api/inquiryForm.js';

export default {
  components: {
    xzTable,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
    isEncryptable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '备件图片',
          dataIndex: 'imageList',
          key: 'imageList',
          width: 120,
          scopedSlots: { customRender: 'imageList' },
          ellipsis: true,
        },
        {
          title: '备件编码',
          dataIndex: 'code',
          key: 'code',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件名称',
          dataIndex: 'name',
          key: 'name',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件型号',
          dataIndex: 'model',
          key: 'model',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备件规格',
          dataIndex: 'spec',
          key: 'spec',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '数量（单位）',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: '兼容性',
          dataIndex: 'compatibilityRequirementDesc',
          key: 'compatibilityRequirementDesc',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '关联设备',
          dataIndex: 'device',
          key: 'device',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '参数',
          dataIndex: '',
          key: 'parameter',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
      ],
      style1: {
        'margin-top': '8px',
        'padding': '18px 18px 0',
        'border-bottom-left-radius': 0,
        'border-bottom-right-radius': 0,
      },
      style2: {
        'padding': '0 18px 0',
        'border-radius': 0,
      },
      style3: {
        'padding': '0 18px 0',
        'border-top-left-radius': 0,
        'border-top-right-radius': 0,
      },
      style4: {
        'margin-top': '8px',
        'padding': '18px 18px 0',
        'border-radius': '6px',
      },
      loading: false,
    };
  },
  methods: {
    handleEncrypt (v, lastIndex) {
      let ret = v;

      if (v) {
        ret = this.isEncryptable ? `${v.slice(0, lastIndex || v.length).replace(/./g, '*')}${lastIndex ? '公司' : ''}` : ret;
      }

      return ret || '--';
    },
    formatDate (text, fmat = 'YYYY-MM-DD') {
      return text ? Moment(text).format(fmat) : '';
    },
    defaultFormatReceiverInfo (v) {
      let ret = v;

      if (v && (!this.info.enteredFlag || this.isEncryptable)) {
        ret = v.replace(/./g, '*');
      }

      return ret || '--';
    },
    defaultFormat (v) {
      return v || v === 0 ? v : '--';
    },
    getDesc (key) {
      return this.info?.deviceList?.[0][key] || '--';
    },
    download ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async handleExport () {
      try {
        this.loading = true;
        const { data } = await inquirePriceExportSparePart({ inquirePriceId: this.info.id });

        this.download({
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `需求清单_${Moment(Date.now()).format('YYYYMMDDHHmmss')}`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.el-descriptions {
  background-color: #fff;
  border-radius: 6px;
  ;

  .el-descriptions__header {
    margin-bottom: 10px;

    .el-descriptions__title {
      font-size: 14px;
    }
  }

  &.device-table {
    .el-descriptions__header {
      margin-bottom: 0px;
    }

    .el-descriptions-item__content {
      overflow: auto;
    }
  }
}
</style>
