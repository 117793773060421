
import Vue from 'vue';
import { throttle } from 'lodash';

export default {
  computed: {
    components () {
      return this.initDrag(this.columns);
    },
  },
  methods: {
    initDrag (columns) {
      const draggingMap = {};
      columns.forEach((col) => {
        const k = col.dataIndex || col.key;
        draggingMap[k] = col.width;
      });
      const draggingState = Vue.observable(draggingMap);
      const resizeableTitle = (h, props, children) => {
        let thDom = null;
        const { key, ...restProps } = props;

        const col = this.columns.find((item) => {
          const k = item.dataIndex || item.key;
          return k === key;
        });

        if (!col || !col.width) {
          return <th {...restProps}>{children}</th>;
        }

        const handleDrag = (x) => {
          draggingState[key] = 0;

          x = Math.max(80, x);

          col.width = Math.max(x, draggingState[key]);
        };
        const onDrag = throttle(handleDrag, 200);
        const onDragstop = () => {
          draggingState[key] = thDom.getBoundingClientRect().width;
        };
        return (
          <th
            {...restProps}
            v-ant-ref={(r) => { thDom = r; }}
            width={draggingState[key]}
            class="resize-table-th"
          >
            {children}
            <vue-draggable-resizable
              key={col.dataIndex || col.key}
              class="table-draggable-handle"
              w={10}
              x={ (col.width || draggingState[key]) * 1 }
              z={1}
              axis="x"
              draggable={true}
              resizable={false}
              onDragging={onDrag}
              onDragstop={onDragstop}
            ></vue-draggable-resizable>
          </th>
        );
      };

      return {
        header: {
          cell: resizeableTitle,
        },
      };
    },
  },
};
