import server from '../request/encryptRequest.js';

export const inquirePricePageList = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/pageListByTenant', params);

export const inquirePriceCount = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/count', params);

export const inquirePriceDetail = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/detailByTenant', params);

export const dynamicInformationPageList = (params) => server.post('/xiaozi-xmo/wireless/saas/dynamicInformation/pageList', params);

export const inquireQuotePriceDetail = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/detail', params);

export const inquireQuotePriceHistoryQuotePriceList = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/historyQuotePriceList', params);

// 派单
export const requirePriceDispatchWork = (params) => server.post('/xiaozi-xmb/wireless/saas/workOrder/requirePriceDispatchWork', params);

// 根据工单来源和来源id查询工单信息
export const getListByOrderSourceAndNo = (params) => server.post('/xiaozi-xmb/wireless/saas/workOrder/getListByOrderSourceAndNo', params);

// 拒单
export const inquirePriceReject = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/reject', params);

// 抢单
export const inquirePriceScramble = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/scramble', params);

// 接单
export const inquirePriceTaskOrder = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/taskOrder', params);

// 转单
export const inquirePriceTransfer = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/transfer', params);

// 确认报价
export const inquireQuotePriceConfirm = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/confirm', params);

// 新增报价
export const inquireQuotePriceAdd = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/add', params);

// 修改报价
export const inquireQuotePriceUpdate = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/update', params);

// 查看最新报价信息
export const inquireQuotePriceNewestDetail = (params) => server.post('/xiaozi-xmo/wireless/saas/inquireQuotePrice/newestDetail', params);

// 字典值查询
export const dictionaryData = (params) => server.post('/xiaozi-xmo/wireless/saas/dataDictionary/getOptionList', params);

// 导出
export const inquirePriceExportSparePart = (params) => server.post('xiaozi-xmo/wireless/saas/inquirePrice/exportSparePart', params, {
  responseType: 'blob',
});

// 查询推荐商机分页列表
export const inquirePricePageListByRecommend = (params) => server.post('/xiaozi-xmo/wireless/saas/inquirePrice/pageListByRecommend', params);

